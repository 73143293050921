import { Button } from "@/Components/ui/button";
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@/Components/ui/carousel";
import { Icon } from "@iconify/react";
import { Link } from "@inertiajs/react";
import { Img } from "react-image";

export default function CategoriesBlock({ categories }) {

    return <div className="w-screen  flex flex-col justify-center items-center h-full pt-12 pb-6 bg-white" >
        <div className="flex flex-row justify-between md:py-6 py-2 md:px-0 px-4 items-center max-w-7xl w-full">
            <h1 data-aos="fade-down" className="md:text-3xl text-lg mb-4 text-center font-semibold font-nunito italic text-primary uppercase">
                Categories
            </h1>
            <Link href={route("home.categories-products")}>
                <Button variant="theme" size="sm">
                    <span className="md:inline-flex hidden">View All Categories</span>
                    <Icon icon="solar:double-alt-arrow-right-broken" width="30" height="30" />
                </Button>
            </Link>
        </div>
        {categories?.length > 0 && <div className="container flex flex-row flex-wrap gap-4 p-4 justify-center items-center">
            <Carousel data-aos="fade-down">
                <CarouselContent className="gap-4 px-4">
                    {categories.map((i) => (
                        <CarouselItem
                            key={i.id}
                            className="md:basis-1/5 w-full pb-[50px] relative border  bg-[#f3f3f3] group mx-auto flex justify-center items-center flex-col rounded-md overflow-hidden"
                        >
                            <Img src={i.image_url}
                                className="h-[250px] group-hover:scale-110 duration-300 bg-white cursor-pointer bg-cover bg-no-repeat aspect-square flex flex-col justify-center items-center"
                            ></Img>
                            <Link href={route("home.predesign-products", { category: i.slug, })}
                                className="w-full absolute text-center group-hover:underline group-hover:text-accent bottom-0 left-0 bg-primary  p-4 text-white"
                            >
                                {i.name}
                            </Link>
                        </CarouselItem>
                    ))}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
            </Carousel>
        </div>
        }
    </div >
}
